/* global CarComponent */
class CarComponentCompacted extends CarComponent {

    static name() {
        return "CarComponentCompacted";
    }

    static componentName() {
        return "CarComponentCompacted";
    }

    getTemplate(){
        return `<div id="carModalCompact" class="row cart-items" v-if="!viewIsLoading">
                  <h5 class="col-12 title">{{tr("Your Purchase")}}</h5>
                  <div class="col-12">
                     <addSOFavModalComponent :from="'car-items'"></addSOFavModalComponent>
                     <template v-if="detailItem">
                        <carModalDetailComponent :item="detailItem" :closeCallback="closeDetail" ></carModalDetailComponent>
                    </template>
                    <template v-if="errors.length>0">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <template v-for="error of errors">
                                <p>{{tr(error)}}</p>
                            </template>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </template>
                     <div class="col-12 px-0 px-sm-3">
                          <div class="stepwizard">
                              <div class="row stepwizard-row setup-panel">
                                  <div class="col stepwizard-step px-1">
                                      <button @click="navListItems(1)" :class="{'btn': true, 'active': currentStep==1 , 'disabled': currentStep<1}" :disabled="currentStep<1">1</button>
                                      <p>{{tr("Items in Cart")}}</p>
                                  </div>
                                  <div class="col stepwizard-step px-1">
                                      <button @click="navListItems(2)" :class="{'btn': true, 'active': currentStep==2 , 'disabled': currentStep<2}" :disabled="currentStep<2">2</button>
                                      <p>{{tr("Delivery Data")}}</p>
                                  </div>
                                   <div class="col stepwizard-step px-1">
                                      <button  @click="navListItems(3)" :class="{'btn': true, 'active': currentStep==3 , 'disabled': currentStep<3}" :disabled="currentStep>3">3</button>
                                      <p>{{tr('Payment Method')}}</p>
                                  </div>
                                  <div class="col stepwizard-step px-1">
                                      <button  @click="navListItems(4)" :class="{'btn': true, 'active': currentStep==4 , 'disabled': currentStep<4}" :disabled="currentStep>4">4</button>
                                      <p>{{tr('Result')}}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="stepwizard-content row">
                             <div class="col-12 setup-content order-items px-2 px-sm-3" id="step-1" v-if="currentStep==1">
                                 <div>
                                    <div class="modal fade" id="quickShopModal" tabindex="-1" role="dialog" aria-labelledby="quickShopModalLabel" aria-hidden="true">
                                      <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                          <div class="modal-header">
                                            <h5 class="modal-title" id="quickShopModalLabel">{{tr('Quick Purchase')}}</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1">
                                              <span aria-hidden="true">&times;</span>
                                            </button>
                                          </div>
                                          <div class="modal-body" @keyup.enter="addItemToCar">
                                              <div class="alert alert-danger" role="alert" v-if="searchMessage" tabindex="-1">
                                                {{searchMessage}}
                                              </div>
                                              <div class="row">
                                                <div class="col" >
                                                  <input type="text" id="qShopItemCode" class="form-control" v-model="tempItemCode" :placeholder="tr('Item Code')" tabindex="1">
                                                </div>
                                                <div class="col">
                                                  <input type="number" id="qShopItemQty" class="form-control" v-model="tempItemQty" :placeholder="tr('Qty')" tabindex="2">
                                                </div>
                                                <div class="col">
                                                    <button type="button" class="btn btn-primary" @click="addItemToCar" tabindex="3">
                                                       <i class="fas fa-plus"></i>
                                                    </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="modal-footer" >
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal" tabindex="4">{{tr('Close')}}</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                 </div>
                                 <div v-if="$store.getters.useDiscountCoupon || $store.getters.useGiftcard" class="col-12 px-1 px-md-3">
                                     <div class="bordered my-2">
                                         <div class="form-row px-2 py-2">
                                             <div v-if="$store.getters.useDiscountCoupon" class="col-12 px-3" :class="{'col-md-6 mb-4 mb-md-0': $store.getters.useGiftcard}">
                                                 <h6 class="col-12 mb-3 px-0 align-items-center d-flex"><i class="delivery-icon fas fa-tag fa-2x mr-3" style="font-size: 25px;"></i>{{tr('Discount')}}</h6>
                                                 <div class="form-row">
                                                     <input class="col-8 form-control" :placeholder="tr('Enter here your discount code')" v-model="orderCampaign" >
                                                     <button type="button" class="col ml-2 btn btn-custom" @click="calculateDiscount">{{tr("Apply")}}</button>
                                                 </div>
                                             </div>
                                             <div v-if="$store.getters.useGiftcard" class="col-12 px-3" :class="{'col-md-6': $store.getters.useDiscountCoupon}">
                                                 <h6 class="col-12 mb-3 px-0 align-items-center d-flex"><i class="delivery-icon fas fa-gift fa-2x mr-3" style="font-size: 25px;"></i>{{tr('Giftcard')}}</h6>
                                                 <div class="form-row">
                                                     <input class="col-8 form-control" :placeholder="tr('Enter here your Giftcard code')" v-model="giftCardCode">
                                                     <button type="button" class="col ml-2 btn btn-custom btn btn-custom" @click="addGiftcard">{{tr("Apply")}}</button>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="table-responsive">
                                     <table class="table table-striped">
                                         <thead>
                                             <tr>
                                                 <th scope="col">{{tr("Code")}}</th>
                                                 <th scope="col">{{tr("Item")}}</th>
                                                 <th scope="col">{{tr("Unit")}}</th>
                                                 <th scope="col">{{tr("Price")}} {{showPriceLabel()}}</th>
                                                 <template v-if="$store.getters.canUseOrderQtyFormula">
                                                    <template v-for="field of $store.getters.getQtyFormulaFields">
                                                        <th scope="col">{{tr(field)}}</th>
                                                    </template>
                                                </template>
                                                 <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                 <th scope="col" class="text-right">{{tr("Total")}} {{showPriceLabel()}}</th>
                                                 <th></th>
                                             </tr>
                                         </thead>
                                         <tbody v-if="orderTemplate">
                                            <template v-for="item in getOrderItems">
                                             <template v-if="item.message">
                                               <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >
                                                   <td scope="row" >{{item.fields.ArtCode}}</td>
                                                   <td colspan="4">
                                                      <a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>
                                                   </td>
                                                   <td colspan="3">
                                                      {{tr(item.message)}}
                                                   </td>
                                                    <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                      <span class="row"  :title="tr('Delete Element from Car')">
                                                          <i class="fas fa-trash"></i>
                                                      </span>
                                                   </td>
                                                </tr>
                                             </template>
                                             <template v-else>
                                                 <tr>
                                                   <th scope="row" >{{item.fields.ArtCode}}</th>
                                                   <td @click="viewDetailItem(item)">
                                                      <a href="#" class="item-link" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}}</a>
                                                   </td>
                                                   <td scope="row">{{item.fields.Unit}}</td>
                                                   <td scope="row" class="center" >
                                                       <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                           <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                {{number_format(item.fields.VATPrice,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                           </template>
                                                           <template v-else>
                                                                {{number_format(item.fields.Price,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                            </template>
                                                       </template>
                                                       <template v-else>
                                                           --
                                                       </template>
                                                   </td>
                                                   <template v-if="$store.getters.canUseOrderQtyFormula">
                                                        <template v-for="field of $store.getters.getQtyFormulaFields">
                                                            <td scope="col"> {{number_format(item.fields[field],2)}}</td>
                                                        </template>
                                                   </template>
                                                   <td scope="row" colspan="3" class="center" >
                                                      <div class="form-inline row  justify-content-center">
                                                          <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                              <i class="fas fa-plus-circle waves-effect"></i>
                                                          </label>
                                                          <input :readonly="$store.getters.canUseOrderQtyFormula" :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @change="updateCarQty">
                                                          <label class="sr-only form-control"@click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                              <i class="fas fa-minus-circle waves-effect"></i>
                                                          </label>
                                                       </div>
                                                   </td>
                                                   <td class="text-right">
                                                       <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                           <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                {{number_format(item.fields.RowTotal,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                           </template>
                                                           <template v-else>
                                                                {{number_format(item.fields.RowNet,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                            </template>
                                                       </template>
                                                       <template v-else>
                                                           --
                                                       </template>
                                                   </td>
                                                   <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                      <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                          <i class="fas fa-trash"></i>
                                                      </span>
                                                   </td>
                                                 </tr>
                                             </template>
                                            </template>
                                            <tr v-if="getDiscountCoupon">
                                                <td :colspan="$store.getters.canUseOrderQtyFormula ? 9+$store.getters.getQtyFormulaFields.length : 9" class="text-left text-md-right">
                                                  <template >
                                                    <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                  </template>
                                                </td>
                                            </tr>
                                            <tr v-if="getShipCost!=null">
                                                <td :colspan="$store.getters.canUseOrderQtyFormula ? 9+$store.getters.getQtyFormulaFields.length : 9" class="text-left text-md-right">
                                                  <template>
                                                    <strong>{{tr("Ship Cost")}}: $ {{getShipCost}}</strong> 
                                                  </template>
                                                </td>
                                            </tr>
                                             <tr v-if="getPayTermSurcharge">
                                                <td colspan="8" class="text-left text-md-right">
                                                     <strong>{{getPayTermSurchargeLable('MP')}}: $ {{getPayTermSurcharge}} </strong>
                                                </td>
                                            </tr>
                                            <tr v-if="$store.getters.showTaxInOrder && getOrderField('TotalTax',null)">
                                                <td :colspan="$store.getters.canUseOrderQtyFormula ? 9+$store.getters.getQtyFormulaFields.length : 9" class="text-right">
                                                    <strong class="mr-2">Percepciones</strong>
                                                    <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                        <strong>$ {{number_format(getOrderField('TotalTax',0),2)}}</strong>
                                                    </template>
                                                    <template v-else>
                                                        --
                                                    </template>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td :colspan="$store.getters.canUseOrderQtyFormula ? 9+$store.getters.getQtyFormulaFields.length : 9" class="text-left text-md-right">
                                                    <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                        <strong class="mr-2">Total (IVA INC)</strong>
                                                    </template>
                                                    <template v-else>
                                                        <strong class="mr-2">Total (IVA EXC)</strong>
                                                    </template>
                                                    <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                       <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                            <strong>$ {{number_format(getOrderField('Total',0),2)}}</strong> <small v-if="Currency"> {{Currency}}</small>
                                                       </template>
                                                       <template v-else>
                                                            <strong>$ {{number_format(getOrderField('SubTotal',0),2)}}</strong> <small v-if="Currency"> {{Currency}}</small>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        --
                                                    </template>
                                                </td>
                                            </tr>
                                         </tbody>
                                     </table>
                                     <div class="row mt-2 mx-0">
                                          <div class="col-12 col-md-6 text-center text-md-left">
                                              <button key="btn-0-step-2" class="btn btn-success mb-2" type="button" data-toggle="modal" data-target="#quickShopModal">
                                                <i class="fas fa-cart-plus"></i>
                                                <span>{{tr('Quick Purchase')}}</span>
                                              </button>
                                              <button key="btn-1-step-1" @click="goToShop()" class="btn btn-secondary mb-2">
                                                  <i class="fas fa-cart-plus mr-1"></i>
                                                  {{tr('Continue Shopping')}}
                                              </button>
                                              <button key="btn-4-step-1" @click="clearCar()" class="btn btn-secondary mb-2">
                                                  <i class="fas fa-trash mr-1"></i>
                                                  {{tr('Clear')}}
                                              </button>
                                          </div>
                                          <div class="col-12 col-md-6 order-first order-md-last text-center text-md-right">
                                              <button key="brn-2-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-secondary mb-2" data-toggle="modal" data-target="#addSOFavModal">
                                                  <i class="fas fa-star mr-1"></i>
                                                  {{tr('Add To Favorite Orders')}}
                                              </button>
                                              <button key="brn-3-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-success mb-2 nextBtn" type="button" @click="nextStep($event)">
                                                  <span>{{tr('Delivery Data')}}</span>
                                                  <i class="fas fa-arrow-right ml-1"></i>
                                              </button>
                                          </div>
                                     </div>
                                 </div>
                             </div>
                             <div class="col-12 setup-content" id="step-2" v-if="currentStep==2">
                                  <div class="row">
                                      <div class="col-12 col-md-7">
                                          <div class="row">
                                            <carCenterCostList />
                                          </div>
                                          <div class="row">
                                            <carDeliveryListCompacted @deliveryselected="calculateOrderTotals()" @shipModeChange="calculateShipCost()"/>
                                          </div>
                                          
                                      </div>
                                      <div class="col-12 col-md-5">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                 <thead>
                                                     <tr>   
                                                         <th scope="col">{{tr("Item")}}</th>
                                                         <th scope="col">{{tr("Price")}} {{showPriceLabel()}}</th>
                                                         <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                         <th scope="col" class="text-right">{{tr("Total")}} {{showPriceLabel()}}</th>
                                                         <th></th>
                                                     </tr>
                                                 </thead>
                                                 <tbody v-if="orderTemplate">
                                                    <template v-for="item in getOrderItems">
                                                     <template v-if="item.message">
                                                       <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >
                                                          
                                                           <td colspan="4">
                                                              <a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>
                                                           </td>
                                                           <td colspan="3">
                                                              {{tr(item.message)}}
                                                           </td>
                                                            <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                              <span class="row"  :title="tr('Delete Element from Car')">
                                                                  <i class="fas fa-trash"></i>
                                                              </span>
                                                           </td>
                                                        </tr>
                                                     </template>
                                                     <template v-else>
                                                         <tr :key="'carItem-'+item.fields.ArtCode">
                                                           <td @click="viewDetailItem(item)">
                                                              <a href="#" class="item-link" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}}</a>
                                                           </td>
                                                          
                                                           <td scope="row" class="center" >
                                                               <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                   <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                        {{number_format(item.fields.VATPrice,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                                   </template>
                                                                   <template v-else>
                                                                        {{number_format(item.fields.Price,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                                    </template>
                                                               </template>
                                                               <template v-else>
                                                                   --
                                                               </template>
                                                           </td>
                                                           <td scope="row" colspan="3" class="center" >
                                                              <div class="form-inline row  justify-content-center">
                                                                  <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                                      <i class="fas fa-plus-circle waves-effect"></i>
                                                                  </label>
                                                                  <input :readonly="$store.getters.canUseOrderQtyFormula" :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @change="updateCarQty">
                                                                  <label class="sr-only form-control"@click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                                      <i class="fas fa-minus-circle waves-effect"></i>
                                                                  </label>
                                                               </div>
                                                           </td>
                                                           <td class="text-right">
                                                               <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                   <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                        {{number_format(item.fields.RowTotal,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                                   </template>
                                                                   <template v-else>
                                                                        {{number_format(item.fields.RowNet,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                                    </template>
                                                               </template>
                                                               <template v-else>
                                                                   --
                                                               </template>
                                                           </td>
                                                           <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                              <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                                  <i class="fas fa-trash"></i>
                                                              </span>
                                                           </td>
                                                         </tr>
                                                     </template>
                                                    </template>
                                                    <tr v-if="getDiscountCoupon">
                                                        <td colspan="7" class="text-left text-md-right">
                                                          <template >
                                                            <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                          </template>
                                                        </td>
                                                    </tr>
                                                     <tr v-if="getPayTermSurcharge">
                                                        <td colspan="7" class="text-left text-md-right">
                                                             <strong>{{getPayTermSurchargeLable('MP')}}: $ {{getPayTermSurcharge}} </strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="getShipCost!=null">
                                                        <td colspan="7" class="text-left text-md-right">
                                                          <template>
                                                            <strong>{{tr("Ship Cost")}}: $ {{getShipCost}}</strong> 
                                                          </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="$store.getters.showTaxInOrder && orderTemplate.fields.TotalTax">
                                                        <td colspan="9" class="text-right">
                                                            <strong class="mr-2">Percepciones</strong>
                                                            <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                <strong>$ {{number_format(getOrderField('TotalTax',0),2)}}</strong>
                                                            </template>
                                                            <template v-else>
                                                                --
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="7" class="text-left text-md-right">
                                                            <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                <strong class="mr-2">Total (IVA INC)</strong>
                                                           </template>
                                                           <template v-else>
                                                                <strong class="mr-2">Total (IVA EXC)</strong>
                                                            </template>
                                                            <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                               <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                    <strong>$ {{number_format(getOrderField('Total',0),2)}}</strong><small v-if="Currency"> {{Currency}}</small>
                                                               </template>
                                                               <template v-else>
                                                                    <strong>$ {{number_format(getOrderField('SubTotal',0),2)}}</strong><small v-if="Currency"> {{Currency}}</small>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                --
                                                            </template>
                                                        </td>
                                                    </tr>
                                                 </tbody>
                                             </table>
                                        </div>
                                      </div>
                                      <div class="col-12">
                                          <div class="text-right align-self-end">
                                              <button key="btn-1-step-2" class="btn btn-secondary prevBtn float-left mb-2" type="button" @click="prevStep()">
                                                    <i class="fas fa-arrow-left"></i>
                                                    <span>{{tr('Back to Items in Cart')}}</span>
                                              </button>
                                              <button key="btn-2-step-2" @click="nextStep($event)" v-bind:class="{btn:true, 'btn-success':true, disabled:itemsSelected.length==0}">{{tr('Continue')}}</button>
                                          </div>
                                      </div>
                                  </div>
                             </div>
                             <div class="col-12 setup-content" id="step-3" v-if="currentStep==3">
                                  <div class="row">
                                      <div class="col-12 col-md-7">
                                          <div class="col-12 mt-3">
                                              <div class="form-row m-0 mb-3 px-2 bordered">
                                                 <h6 class="col-12 align-items-center d-flex"><i class="delivery-icon fas fa-credit-card fa-2x mr-3" style="font-size: 1.6em;"></i>{{tr('Select a Pay Term')}}</h6>
                                                 <div class="form-group col-6">
                                                      <label class="col-form-label">{{tr("Payment Method")}}</label>
                                                      <selectLinkToComponent class="form-group" v-model="currentPayTerm" :table="'PayTerm'" :paste="'Code'" :showField="'Name'" :filters="payTermFilter" :selected="payTerm"  @update-value-full="(data)=>updateSelectLinktoValues('PayTerm',data)"> </selectLinkToComponent>
                                                 </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-12 col-md-5">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                             <thead>
                                                 <tr>   
                                                     <th scope="col">{{tr("Item")}}</th>
                                                     <th scope="col">{{tr("Price")}} {{showPriceLabel()}}</th>
                                                     <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                     <th scope="col" class="text-right">{{tr("Total")}} {{showPriceLabel()}}</th>
                                                     <th></th>
                                                 </tr>
                                             </thead>
                                             <tbody v-if="orderTemplate">
                                                <template v-for="item in getOrderItems">
                                                 <template v-if="item.message">
                                                   <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >
                                                      
                                                       <td colspan="4">
                                                          <a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>
                                                       </td>
                                                       <td colspan="3">
                                                          {{tr(item.message)}}
                                                       </td>
                                                        <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                          <span class="row"  :title="tr('Delete Element from Car')">
                                                              <i class="fas fa-trash"></i>
                                                          </span>
                                                       </td>
                                                    </tr>
                                                 </template>
                                                 <template v-else>
                                                     <tr :key="'carItem-'+item.fields.ArtCode">
                                                       <td @click="viewDetailItem(item)">
                                                          <a href="#" class="item-link" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}}</a>
                                                       </td>
                                                       <td scope="row" class="center" >
                                                           <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                               <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                    {{number_format(item.fields.VATPrice,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                               </template>
                                                               <template v-else>
                                                                    {{number_format(item.fields.Price,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                                </template>
                                                           </template>
                                                           <template v-else>
                                                               --
                                                           </template>
                                                       </td>
                                                       <td scope="row" colspan="3" class="center" >
                                                          <div class="form-inline row  justify-content-center">
                                                              <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                                  <i class="fas fa-plus-circle waves-effect"></i>
                                                              </label>
                                                              <input :readonly="$store.getters.canUseOrderQtyFormula" :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @change="updateCarQty">
                                                              <label class="sr-only form-control"@click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                                  <i class="fas fa-minus-circle waves-effect"></i>
                                                              </label>
                                                           </div>
                                                       </td>
                                                       <td class="text-right">
                                                           <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                               <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                    {{number_format(item.fields.RowTotal,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                               </template>
                                                               <template v-else>
                                                                    {{number_format(item.fields.RowNet,2)}}<small v-if="Currency"> {{Currency}}</small>
                                                                </template>
                                                           </template>
                                                           <template v-else>
                                                               --
                                                           </template>
                                                       </td>
                                                       <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                          <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                              <i class="fas fa-trash"></i>
                                                          </span>
                                                       </td>
                                                     </tr>
                                                 </template>
                                                </template>
                                                <template v-if="$store.getters.showTaxInOrder">
                                                    <tr v-if="$store.getters.getSetting.ShowPriceWithIVA">
                                                         <td :colspan="$store.getters.canUseOrderQtyFormula ? 9+$store.getters.getQtyFormulaFields.length : 9" class="text-right" >
                                                              <strong class="mr-2">Sub Total</strong>
                                                              <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                  <strong>$ {{number_format(getOrderField('SubTotal',0),2)}}</strong>
                                                              </template>
                                                              <template v-else>
                                                                  --
                                                              </template>
                                                          </td>
                                                    </tr>
                                                    <tr>
                                                        <td :colspan="$store.getters.canUseOrderQtyFormula ? 9+$store.getters.getQtyFormulaFields.length : 9" class="text-right">
                                                            <strong class="mr-2">Total IVA</strong>
                                                            <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                <strong>$ {{number_format(getOrderField('VATTotal',0),2)}}</strong>
                                                            </template>
                                                            <template v-else>
                                                                --
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td :colspan="$store.getters.canUseOrderQtyFormula ? 9+$store.getters.getQtyFormulaFields.length : 9"  class="text-right">
                                                            <strong class="mr-2">Percepciones</strong>
                                                            <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                <strong>$ {{number_format(getOrderField('TotalTax',0),2)}}</strong>
                                                            </template>
                                                            <template v-else>
                                                                --
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <tr v-if="getDiscountCoupon">
                                                    <td :colspan="$store.getters.canUseOrderQtyFormula ? 7+$store.getters.getQtyFormulaFields.length : 7" class="text-left text-md-right">
                                                      <template >
                                                        <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                      </template>
                                                    </td>
                                                </tr>
                                                 <tr v-if="getShipCost!=null">
                                                    <td:colspan="$store.getters.canUseOrderQtyFormula ? 7+$store.getters.getQtyFormulaFields.length : 7" class="text-left text-md-right">
                                                      <template>
                                                        <strong>{{tr("Ship Cost")}}: $ {{getShipCost}}</strong> 
                                                      </template>
                                                    </td>
                                                </tr>
                                                <tr v-if="getPayTermSurcharge">
                                                    <td :colspan="$store.getters.canUseOrderQtyFormula ? 7+$store.getters.getQtyFormulaFields.length : 7" class="text-left text-md-right">
                                                         <strong>{{getPayTermSurchargeLable()}}: $ {{getPayTermSurcharge}} </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td :colspan="$store.getters.canUseOrderQtyFormula ? 7+$store.getters.getQtyFormulaFields.length : 7" class="text-left text-md-right">
                                                        <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                            <strong class="mr-2">Total (IVA INC)</strong>
                                                       </template>
                                                       <template v-else>
                                                            <strong class="mr-2">Total (IVA EXC)</strong>
                                                        </template>
                                                        <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                           <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                <strong>$ {{number_format(getOrderField('Total',0),2)}}</strong><small v-if="Currency"> {{Currency}}</small>
                                                           </template>
                                                           <template v-else>
                                                                <strong>$ {{number_format(getOrderField('SubTotal',0),2)}}</strong><small v-if="Currency"> {{Currency}}</small>
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            --
                                                        </template>
                                                    </td>
                                                </tr>
                                             </tbody>
                                            </table>
                                        </div>
                                      </div>
                                      <div class="col-12">
                                          <div class="text-right align-self-end">
                                              <button key="btn-1-step-2" class="btn btn-secondary prevBtn float-left mb-2" type="button" @click="prevStep()">
                                                    <i class="fas fa-arrow-left"></i>
                                                    <span>{{tr('Back to Items in Cart')}}</span>
                                              </button>
                                              <button key="btn-2-step-2" @click="payAndClose($event)" v-bind:class="{btn:true, 'btn-success':true, disabled:itemsSelected.length==0}">{{tr('Checkout')}}</button>
                                          </div>
                                      </div>
                                  </div>
                             </div>
                             <div class="col-12 setup-content my-4" id="step-4" v-if="currentStep==4">
                                 <div class="col-12">
                                     <template v-if="status=='loading'">
                                         <div class="">
                                             <h5 class="text-center">{{tr("Your Order is processing")}}.{{tr("Please Wait")}}.</h5>
                                         </div>
                                     </template>
                                     <template v-if="status=='failure'">
                                         <h5 class="text-center">{{tr("The Sales Order can't be created")}}.</h5>
                                         <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message px-1">
                                            <template v-for="msg_error of result.error" >
                                                <oppenErrorDisplay :errorData="msg_error" />
                                             </template>
                                         </div>
                                         <button class="btn btn-secondary prevBtn float-left" type="button" @click="navListItems(1)">
                                                <i class="fas fa-arrow-left"></i>
                                                <span>{{tr('Back to Items in Cart')}}</span>
                                         </button>
                                     </template>
                                     <template  v-if="status=='result'">
                                         <h5 class="text-center">{{ tr([successMessage,result.so.fields.SerNr]) }}.</h5>
                                         <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message my-3 px-1 pt-2">
                                             <h5 class="text-center">{{tr("Information")}}:</h5>
                                             <p v-for="error of result.error" class="text-center">{{tr(error)}}</p>
                                         </div>
                                         <template v-if="mpAvailable || redirectAvailable">
                                            <div class="col-12 col-md-6 offset-md-3 alert alert-warning px-1" role="alert" v-if="paymentMsg">
                                                <template v-if="Array.isArray(paymentMsg)">
                                                     <p v-for="error of paymentMsg" class="text-center">{{tr(error.description.charAt(0).toUpperCase() + error.description.slice(1))}}</p>
                                                </template>
                                                <template v-else>
                                                    <p class="text-center">{{tr(paymentMsg)}}</p>
                                                </template>
                                            </div>
                                            <template v-if="paymentIsProcess">
                                                <div class="col-12 col-md-6 offset-md-3 alert alert-success text-center" role="alert">
                                                    <icon name="regular/check-circle" scale="5" class="col-6"/>
                                                    <h4 class="col-12 text-center mt-2">{{tr("The payment was approved successfully")}}</h4>
                                                    <strong class="d-block mt-2">{{tr('It may take a few minutes to be reflected')}}</strong>
                                                </div>
                                            </template>
                                            <div class="row justify-content-center mt-4"  v-if="doOnlinePayment">
                                                <template v-if="$store.getters.getSetting.MercadoPagoMode==1">
                                                    <mercadopagoFormCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoFormCustom>
                                                </template>
                                                <template v-else-if='$store.getters.getSetting.MercadoPagoMode==0'>
                                                    <mercadopagoCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoCustom>
                                                </template>
                                                <template v-else-if='$store.getters.getSetting.MercadoPagoMode==2'>
                                                    <redirectPaymentComponent :SO='result.so.fields.SerNr' :PayTermObj='getPayTermObj()'/>
                                                </template>
                                            </div>
                                        </template>
                                        <div class="row justify-content-center mt-4">
                                           <router-link key="btn-1-step-3" to="/profile" class="btn btn-custom">{{tr('View Shopping List')}}</router-link>
                                        </div>
                                    </template>
                                 </div>
                             </div>
                          </div>
                      </div>
                  </div>
              </div>`;
    }

}

CarComponentCompacted.registerComponent();